import React from "react";

function Header() {
  return (
    <div className="footer">
      <div>Designed and Developed by Varun Bardwaj</div>
      <div style={{marginTop: "0.4rem"}}>Released under MIT Licence</div>
    </div>
  );
}

export default Header;
