import React from "react";

function Table({ header, definition, style }) {
  return (
    <div style={{ borderRadius: "8px", width: "100%", overflow: "auto", marginTop: "4rem", ...style }}>
      <table
        style={{
          borderTop: "1px solid #F9FAFB",
          borderBottom: "1px solid #F9FAFB",
          borderSpacing: "0",
          width: "100%",
          color: "#071528",
        }}
      >
        <thead>
          <tr style={{ margin: 0, backgroundColor: "#F9FAFB" }}>
            {header &&
              header.map((item, index) => (
                <th
                  key={`header-${index}`}
                  style={{ textAlign: "left", padding: "1rem", margin: "0" }}
                >
                  {item}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {definition.map((item, index) => (
            <tr key={`tr-${index}`}>
              {item.map((item, index) => (
                <td
                  key={`td-${index}`}
                  style={{
                    padding: "1rem",
                    borderTop: "1px solid #F9FAFB",
                  }}
                >
                  {item}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
