import React from "react";
import LOGO from "../logo.png";

function Header() {
  return (
    <div className="header">
      <div className="brand">
        <img src={LOGO} alt="logo" />
        React Logger
      </div>
      <i
        onClick={() => {
          window.open("https://github.com/varunpbardwaj/react-logger-js");
        }}
        className="fab fa-github"
      />
    </div>
  );
}

export default Header;
