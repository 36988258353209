import "./App.css";
import Header from "./components/Header";
import Card from "./components/Card";
import Table from "./components/Table";
import Footer from "./components/Footer";
import $ from "jquery";

function App() {
  return (
    <div className="app">
      <Header />
      <div className="spacer-1" />
      <div className="spacer-1" />
      <div className="spacer-1" />
      <div className="spacer-2" />
      <div className="description-wrapper">
        <div className="sub-header">
          <div>A ReactJS library to log the data.</div>
        </div>
        <div className="description">
          <div>
            Get rid of old school console logging and debugging, enjoy the
            colorful and easy to use React Logger UI.
          </div>
        </div>
        <div className="button-wrapper">
          <div
            className="get-started-button"
            onClick={() => {
              $([document.documentElement, document.body]).animate(
                {
                  scrollTop: $("#get-started-wrapper").offset().top - 50,
                },
                500
              );
            }}
          >
            Get Started
          </div>
          <div
            className="demo-button"
            onClick={() => {
              $([document.documentElement, document.body]).animate(
                {
                  scrollTop: $("#iframe-wrapper").offset().top - 50,
                },
                500
              );
            }}
          >
            Demo
          </div>
        </div>
      </div>
      <div className="spacer-1" />
      <div className="spacer-1" />
      <div className="features">
        <div className="card-holder">
          <Card
            title="Reactive"
            description="React Logger uses rxjs under the hood, which makes it reactive and logs will be painted real time."
          />
          <Card
            title="Persist"
            description="Logs will be persisted between the routes. You can time travel and see the old logs."
          />
          <Card
            title="Customizable"
            description="React Logger is fairly customizable. You can control the provider placement, filter and reset the logs."
          />
        </div>
      </div>
      <div className="spacer-1" />
      <div className="spacer-1" />
      <div className="spacer-2" />
      <div id="get-started-wrapper" className="wrapper">
        <div className="heading">
          <span>Getting Started</span>
        </div>
        <div className="spacer-1" />
        <div className="spacer-2" />
        <div className="get-started-wrapper">
          <div className="get-started-heading-1">Installation</div>
          <div className="spacer-2" />
          <div className="get-started-command">
            <pre>npm install react-logger-js</pre>
          </div>
          <div className="spacer-2" />
          <div className="get-started-heading-2">OR</div>
          <div className="spacer-2" />
          <div className="get-started-command">
            <pre>yarn add react-logger-js</pre>
          </div>
          <div className="spacer-1" />
          <div className="spacer-2" />
          <div className="get-started-heading-1">Integration</div>
          <div className="spacer-2" />
          <div className="get-started-command">
            <pre>
              {`import React from "react";
import { LogProvider } from "react-logger-js";

function App() {
  return (
   <>
      <LogProvider
        devURL={["URL 1", "URL 2", .....]}
        x={1}
        y={1}
      />
    </>
  );
}

export default App;`}
            </pre>
          </div>
          <div className="spacer-2" />
          <div className="spacer-2" />
          <div className="get-started-heading-2">Props</div>
          <Table
            style={{ marginTop: "1.5rem" }}
            header={["name", "type", "mandatory", "description"]}
            definition={[
              [
                "devURL",
                "string[]",
                "yes",
                "enables provider on specified URLs",
              ],
              ["x", "0,  1", "yes", "horizontal position"],
              ["y", "0, 1", "yes", "vertical position"],
            ]}
          />
          <div className="spacer-1" />
          <div className="spacer-2" />
          <div className="get-started-heading-1">Usage</div>
          <div className="spacer-2" />
          <div className="get-started-command">
            <pre>
              {`import { makeLogs } from "react-logger-js";
              
makeLogs("flagr", "data");`}
            </pre>
          </div>
          <div className="spacer-2" />
          <div className="spacer-2" />
          <div className="get-started-heading-2">Props</div>
          <Table
            style={{ marginTop: "1.5rem" }}
            header={["name", "type", "mandatory", "description"]}
            definition={[
              ["flagr", "string", "yes", "data indentifier"],
              ["data", "any", "yes", "data to be logged"],
            ]}
          />
        </div>
      </div>
      <div className="spacer-1" />
      <div className="spacer-1" />
      <div className="spacer-1" />
      <div id="iframe-wrapper" className="wrapper">
        <div className="spacer-1" />
        <div className="spacer-2" />
        <div className="heading">
          <span>Click on the Provider at bottom right corner.</span>
        </div>
        <iframe
          src={`https://react-logger-js-demo-varunbardwaj.pages.dev?cache=${new Date().getTime()}`}
          title="A demo application to show case React Logger."
        ></iframe>
        <div className="spacer-1" />
        <div className="spacer-2" />
      </div>
      <Footer />
    </div>
  );
}

export default App;
